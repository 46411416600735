import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { LoaderModule } from '../loader';
import { LoadingOverlayComponent, LoadingSkeletonComponent } from './components';

@NgModule({
  declarations: [LoadingOverlayComponent, LoadingSkeletonComponent],
  imports: [CommonModule, IconModule, LoaderModule],
  exports: [LoadingOverlayComponent, LoadingSkeletonComponent],
})
export class LoadingOverlayModule {}
