import { filter, Subject, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { IconService } from '../../services';
import { IconType } from './icon.types';

export type IconSize = 'tiny' | 'extra-small' | 'small' | 'medium' | 'large' | 'huge' | 'extra-huge';
export type IconColor =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'error'
  | 'success'
  | 'success-strong'
  | 'warn'
  | 'warn-strong'
  | 'info'
  | 'confirm';
export type IconShape = 'normal' | 'rounded';
export type IconRoundedBackgroundSize = 'medium' | 'large';

@Component({
  selector: 'supy-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, OnDestroy {
  @Input() @HostBinding('attr.name') readonly name: IconType;
  @Input() readonly color?: IconColor;
  @Input() readonly size?: IconSize;
  @Input() readonly shape: IconShape = 'normal';
  @Input() readonly roundedBackgroundSize: IconRoundedBackgroundSize = 'medium';
  @Input() readonly loading: boolean = false;
  @Input() readonly customStyle: Record<string, string | number>;

  readonly family: string = IconService.FAMILY;
  private readonly iconFound$ = new Subject<boolean>();

  constructor(private readonly iconService: IconService) {}

  ngOnInit(): void {
    const isCached = this.iconService.isCached(this.name);

    if (isCached) {
      return;
    }

    this.iconService
      .getIcon(this.name)
      .pipe(
        takeUntil(this.iconFound$),
        filter(e => e.name === this.name),
      )
      .subscribe(() => {
        this.setIconLoaded();
      });
  }

  getClasses(): string[] {
    const classes: string[] = [];

    if (this.color) {
      classes.push(`supy-icon--${this.color}`);
    }

    if (this.size) {
      classes.push(`supy-icon--${this.size}`);
    }

    if (this.shape === 'rounded') {
      classes.push(`supy-icon--rounded`, `supy-icon--rounded-${this.color}`);
    }

    if (this.roundedBackgroundSize === 'large') {
      classes.push(`supy-icon--rounded-large`);
    }

    return classes;
  }

  ngOnDestroy(): void {
    this.setIconLoaded();
  }

  private setIconLoaded(): void {
    this.iconFound$.next(true);
    this.iconFound$.complete();
  }
}
