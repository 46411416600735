import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

import { InputValidators } from '@supy/common';

import { AuthParams } from '../../../config';
import { LoginType } from '../../../core';
import { AuthService } from '../../../services';
import { LoginComponent } from '../login.component';

@Component({
  selector: 'supy-login-with-phone',
  templateUrl: './login-with-phone.component.html',
  styleUrls: ['./login-with-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginWithPhoneComponent extends LoginComponent {
  readonly navigation: Navigation | null;
  readonly type: LoginType = LoginType.Phone;
  readonly form = new UntypedFormGroup({
    identifier: new UntypedFormControl(null, [Validators.required, InputValidators.phone]),
    captcha: new UntypedFormControl(null, [Validators.required]),
  });

  readonly loginTypes = LoginType;

  constructor(
    protected readonly authService: AuthService,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly cdr: ChangeDetectorRef,
    readonly authParams: AuthParams,
  ) {
    super();
    this.navigation = this.router.getCurrentNavigation();
  }
}
