import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpParamsOptions,
  HttpRequest,
} from '@angular/common/http';

import { DefaultQueryEncoder } from './default-query.encoder';

export type HttpParamsObject = HttpParamsOptions['fromObject'];
export type HttpHeadersObject = Record<string, string>;
export type HttpConfigObject<T extends keyof typeof HttpClient.prototype> = (typeof HttpClient.prototype)[T];
export type HttpRequestPath = string | number;

export abstract class BaseHttpService {
  protected readonly baseUrl: string;
  protected readonly encoder: HttpParameterCodec;
  protected abstract readonly httpClient: HttpClient;
  constructor(path: string) {
    this.baseUrl = path;
    this.encoder = new DefaultQueryEncoder();
  }

  protected get<TResponse>(
    path: HttpRequestPath = '',
    params?: HttpParamsObject,
    headers?: HttpHeadersObject,
    config?: HttpConfigObject<'get'>,
  ): Observable<TResponse> {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.get<TResponse>(url, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder,
      }),
      headers: new HttpHeaders(headers),
    });
  }

  protected delete<TRequest, TResponse>(
    path: HttpRequestPath = '',
    body?: TRequest,
    params?: HttpParamsObject,
    headers?: HttpHeadersObject,
    config?: HttpConfigObject<'delete'>,
  ): Observable<TResponse> {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.delete<TResponse>(url, {
      ...config,
      body,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder,
      }),
      headers: new HttpHeaders(headers),
    });
  }

  protected post<TRequest, TResponse = TRequest>(
    body: TRequest,
    path: HttpRequestPath = '',
    params?: HttpParamsObject,
    headers?: HttpHeadersObject,
    config?: HttpConfigObject<'post'>,
  ): Observable<TResponse> {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.post<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder,
      }),
      headers: new HttpHeaders(headers),
    });
  }

  protected put<TRequest, TResponse = TRequest>(
    body: TRequest,
    path: HttpRequestPath = '',
    params?: HttpParamsObject,
    headers?: HttpHeadersObject,
    config?: HttpConfigObject<'put'>,
  ): Observable<TResponse> {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.put<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder,
      }),
      headers: new HttpHeaders(headers),
    });
  }

  protected patch<TRequest, TResponse = TRequest>(
    body: TRequest,
    path: HttpRequestPath = '',
    params?: HttpParamsObject,
    headers?: HttpHeadersObject,
    config?: HttpConfigObject<'patch'>,
  ): Observable<TResponse> {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.patch<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder,
      }),
      headers: new HttpHeaders(headers),
    });
  }

  protected request<TRequest, TResponse = TRequest>(
    request: HttpRequest<TRequest>,
    config?: HttpConfigObject<'request'>,
  ): Observable<HttpEvent<TResponse>> {
    return this.httpClient.request<TResponse>({
      ...config,
      params: new HttpParams({
        fromObject: { ...request.params },
        encoder: this.encoder,
      }),
      headers: request.headers,
    } as HttpRequest<unknown>);
  }
}
