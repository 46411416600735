import { finalize, takeUntil } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Directive, signal, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

import { Destroyable } from '@supy/common';
import { CaptchaComponent } from '@supy/components';

import { AuthParams } from '../../config';
import { CAPTCHA_TOKEN_HEADER, LoginType, SendOtpRequest } from '../../core';
import { AuthService } from '../../services';

interface ISignInForm {
  readonly identifier: string;
  readonly captcha: string;
}

@Directive()
export abstract class LoginComponent extends Destroyable {
  abstract readonly type: LoginType;
  abstract readonly form: UntypedFormGroup;
  abstract readonly navigation: Navigation | null;

  protected abstract readonly authService: AuthService;
  protected abstract readonly router: Router;
  protected abstract readonly route: ActivatedRoute;
  protected abstract readonly cdr: ChangeDetectorRef;

  protected readonly authParams: AuthParams;

  protected readonly isLoading = signal<boolean>(false);

  @ViewChild(CaptchaComponent, { static: true }) protected readonly captcha: CaptchaComponent;

  onSubmit(): void {
    if (this.isLoading()) {
      return;
    }

    const value = this.form.value as ISignInForm;

    if (this.form.valid) {
      this.signIn(value);
    }
  }

  protected signIn({ identifier, captcha }: ISignInForm): void {
    const body = { [this.type]: identifier } as Record<LoginType, string> as SendOtpRequest;

    this.isLoading.set(true);

    this.authService
      .sendOtp(body, {
        [CAPTCHA_TOKEN_HEADER]: captcha,
      })
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading.set(false);
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: async () => {
          await this.redirectToOtp({ identifier, captcha });
        },
        error: async (error: HttpErrorResponse) => {
          const status = error.status as HttpStatusCode;

          if (status === HttpStatusCode.Conflict) {
            await this.redirectToOtp({ identifier, captcha });
          }

          if (status === HttpStatusCode.Forbidden) {
            this.captcha.reset();
          }
        },
      });
  }

  private async redirectToOtp({ identifier, captcha }: ISignInForm): Promise<void> {
    await this.router.navigate(['../../otp'], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
      skipLocationChange: true,
      state: {
        identifier,
        captcha,
        type: this.type,
        returnUrl: this.authParams.returnUrl,
      },
    });
  }
}
