import { add, isEqual, isNil, isUndefined, map, reduce } from 'lodash-es';
import { TrackByFunction } from '@angular/core';

import { IdType } from '../models';

export const areArraysEqual = <T>(array1: T[], array2: T[]) => {
  if (array1 === array2) {
    return true;
  }

  if (array1 === null || array2 === null) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((value, index) => value === array2[index]);
};

export function trackByProperty<T extends object>(property: keyof T): TrackByFunction<T> {
  return (_index: number, obj: T) => obj[property];
}

export function trackByIndex<T extends object>(): TrackByFunction<T> {
  return (index: number) => index;
}

export function isOneOf<T>(item: T, items: T[]): boolean {
  return items.includes(item);
}

export function isEmptyArray<T>(value: T[] | null): boolean {
  return isNil(value) || value.length < 1;
}

/**
 * Gets the enum member type for the given value.
 * @param enumType The enum object to get the members from.
 * @param value The value to check for in the enum.
 * @param defaultMember The value to return if the provided value doesn't exist.
 */
export function getEnumMember<T extends Record<string, unknown>>(
  enumType: T,
  value: number | string | null | undefined,
  defaultMember?: T[keyof T],
): T[keyof T] | null {
  if (isExistent(value) && isEnumMember(enumType, value)) {
    return value;
  } else if (isUndefined(defaultMember)) {
    throw new Error(`Missing enum member for value "${String(value)}" and no default was provided.`);
  } else {
    return defaultMember;
  }
}

function isEnumMember<T extends Record<string, unknown>>(enumType: T, value: unknown): value is T[keyof T] {
  return Object.values(enumType).includes(value);
}

export function isExistent<T>(value: T): value is NonNullable<T> {
  return !isNil(value);
}

export function sum(...args: number[]): number {
  return reduce(args, (a, b) => add(a, b), 0);
}

export function sortByKey<T extends object>(items: T[], sortKey: keyof T): T[] {
  return items.sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }

    return aValue > bValue ? 1 : -1;
  });
}

export function sortNestedByKey<T extends object>(items: T[], sortKey: keyof T, childrenKey: keyof T): T[] {
  const res = sortByKey<T>(items, sortKey);

  return res.map(item => {
    const children = item[childrenKey] as T[];

    return !children?.length ? item : { ...item, [childrenKey]: sortNestedByKey<T>(children, sortKey, childrenKey) };
  });
}

export function sortByName<T extends { name: string }>(data: T[]): T[] {
  const dataCopy = [...data];

  dataCopy.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return dataCopy;
}

export function mapToIdType<T extends IdType>(data: T[]): IdType[] {
  return map(data, ({ id }) => ({ id }));
}

export function isNotEqual<T>(value: T, other: T): boolean {
  return !isEqual(value, other);
}
