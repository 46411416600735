@if (isOpen()) {
  <article class="supy-banner {{ size() }} {{ status() }}" @banner>
    <div class="supy-banner--container">
      <supy-icon [name]="iconName()" [size]="size()" [color]="status()"></supy-icon>
      @if (text) {
        <span class="supy-banner--text">{{ text() }}</span>
      } @else {
        <ng-content></ng-content>
      }
    </div>

    @if (closable()) {
      <supy-button type="icon" name="close-banner" (buttonClick)="destroyComponent()" color="default">
        <supy-icon name="close-circle" [color]="status()" [size]="size()"></supy-icon>
      </supy-button>
    }
  </article>
}
