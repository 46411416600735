import { DecimalPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supySiNumber',
})
export class SiNumberPipe implements PipeTransform {
  private readonly decimalPipe: DecimalPipe = inject(DecimalPipe);

  transform(value: string | number, digitsInfo?: string, locale?: string): string {
    const num = typeof value === 'number' ? value : parseFloat(value);

    if (isNaN(num)) {
      return '';
    }

    if (num === 0) {
      return '0';
    }

    if (num < 1000 && num > -1000) {
      return this.decimalPipe.transform(value, digitsInfo, locale);
    }

    const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const sign = num < 0 ? '-1' : '';
    const absNumber = Math.abs(num);
    const tier = (Math.log10(absNumber) / 3) | 0;

    // if zero, we don't need a prefix
    if (tier == 0) {
      return `${absNumber}`;
    }

    // get postfix and determine scale
    const postfix = SI_POSTFIXES[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = absNumber / scale;
    const floored = Math.floor(scaled * 10) / 10;
    // format number and add postfix as suffix
    let str = floored.toFixed(1);

    // remove '.0' case
    str = /\.0$/.test(str) ? str.substring(0, str.length - 2) : str;

    return `${sign}${str}${postfix}`;
  }
}
