<div class="supy-counter-input supy-input--compact">
  <supy-input
    class="supy-counter-input__input"
    [value]="value"
    [name]="name"
    [min]="min"
    [max]="max"
    [step]="0.001"
    type="number"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [centerText]="true"
    (valueChange)="onValueChange($event)"
    (focusOut)="onFocusOut($event)"
    (inputKeydown)="onInputKeydown($event)"
    [density]="density"
    [textSelection]="textSelection"
    autocomplete="off"
    [precision]="precision"
  >
    <ng-template supyPrefix>
      <supy-button
        [disabled]="decreaseDisabled || disabled"
        (buttonClick)="decrease()"
        [name]="name + '-minus'"
        color="default"
      >
        <supy-icon
          name="minus-circle"
          [color]="decreaseDisabled ? 'secondary' : 'primary'"
          [size]="density"
        ></supy-icon>
      </supy-button>
    </ng-template>
    <ng-template supySuffix>
      <supy-button
        [disabled]="increaseDisabled || disabled"
        (buttonClick)="increase()"
        [name]="name + '-plus'"
        color="default"
      >
        <supy-icon
          name="plus-flat-circle"
          [size]="density"
          [color]="increaseDisabled ? 'secondary' : 'primary'"
        ></supy-icon>
      </supy-button>
    </ng-template>
  </supy-input>
</div>
