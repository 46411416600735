<div class="supy-login-phone">
  <div class="supy-login-phone__form">
    <div class="supy-login-phone__section-logo">
      <supy-image *ngIf="authParams.logoUrl" [width]="284" [height]="38" [src]="authParams.logoUrl"></supy-image>
    </div>
    <div class="supy-login-phone__section-title">
      <h1 class="supy-login-phone__title">{{ authParams.title }}</h1>
      <p class="supy-login-phone__subtitle" i18n="@@login.phoneSubtitle">
        Enter your phone number linked to Supy to receive login instructions
      </p>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="supy-login-phone__form-content">
      <div class="supy-login-phone__inputs">
        <div class="supy-login-phone__input">
          <label class="supy-login-phone__label" i18n="@@login.yourPhone">Your Phone number</label>
          <supy-input-with-button>
            <supy-input name="phone" placeholder="+971 56 555 1212 4221" type="tel" formControlName="identifier">
              <supy-input-error *supyErrorIf="'required'" i18n="@@validation.phoneRequired">
                Phone is required
              </supy-input-error>
              <supy-input-error *supyErrorIf="'phone'" i18n="@@validation.phoneInvalid">
                Phone is not valid
              </supy-input-error>
            </supy-input>
            <supy-button supy-button [disabled]="isLoading()" type="submit" color="primary" name="login">
              <supy-icon name="paper-plane"></supy-icon>
            </supy-button>
          </supy-input-with-button>
        </div>
        <div *ngIf="authParams.loginTypes.includes(loginTypes.Email)">
          <a
            class="body-2 supy-anchor--primary"
            [routerLink]="['../email']"
            [state]="navigation?.extras.state"
            i18n="@@login.useEmailInstead"
          >
            Login with email instead
          </a>
        </div>
        <div>
          <supy-captcha formControlName="captcha">
            <supy-input-error *supyErrorIf="'required'" i18n="@@login.captchaRequired">
              You must complete the captcha
            </supy-input-error>
          </supy-captcha>
        </div>
      </div>
    </form>
  </div>
</div>
