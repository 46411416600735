<supy-dialog
  class="supy-import-file-dialog"
  initialState="open"
  position="center"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header>
    <div class="supy-import-file-dialog__header-wrapper">
      <h3 class="supy-import-file-dialog__title">{{ title }}</h3>
    </div>
  </supy-dialog-header>

  <supy-dialog-content>
    <supy-file-upload
      [maxUploads]="1"
      [fileTypes]="fileTypes"
      [maxFileSize]="maxFileSize"
      (changeFiles)="onChangeFiles()"
      (localFileUploaded)="onLocalFileUploaded($event)"
    ></supy-file-upload>

    <div *ngIf="sheetNames().length">
      <p>
        <span i18n="@@common.selectSheetUpload">Select the sheet to upload</span>
      </p>
      <div>
        <supy-select name="sheet-select" placeholder="Select Sheet" (selected)="selectedSheetName.set($event)">
          <supy-select-item *ngFor="let sheetName of sheetNames(); trackBy: trackByIndex" [value]="sheetName">
            {{ sheetName }}
          </supy-select-item>
        </supy-select>
      </div>
    </div>
  </supy-dialog-content>

  <supy-dialog-footer>
    <div class="supy-import-file-dialog__footer">
      <supy-button name="cancel" color="default" width="full" (buttonClick)="closeDialog()">
        <span i18n="@@cancel">Cancel</span>
      </supy-button>

      <supy-button
        name="import"
        color="primary"
        width="full"
        [disabled]="isImportButtonDisabled()"
        (buttonClick)="onImport()"
      >
        <supy-loader size="small" *ngIf="isLoading(); else importIcon"></supy-loader>

        <ng-template #importIcon>
          <supy-icon name="import" size="small"></supy-icon>
        </ng-template>
        <span>{{ importButtonLabel() }}</span>
      </supy-button>
    </div>
  </supy-dialog-footer>
</supy-dialog>
