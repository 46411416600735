import { finalize, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, signal, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Navigation, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { Destroyable } from '@supy/common';
import { CountdownDirective } from '@supy/components';

import { CAPTCHA_TOKEN_HEADER, LoginRequest, LoginType, SendOtpRequest } from '../../core';
import { AuthService } from '../../services';
import { LoginError, LoginSuccess } from '../../store';

interface NavigationState {
  readonly returnUrl?: string;
  readonly type: LoginType;
  readonly identifier: string;
  readonly captcha: string;
}

interface ICompleteSignInForm {
  readonly code: string;
}

@Component({
  selector: 'supy-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpComponent extends Destroyable {
  readonly form = new UntypedFormGroup({
    code: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]),
  });

  readonly isLoading = signal<boolean>(false);

  @ViewChild(CountdownDirective, { static: true }) readonly countdown: CountdownDirective;

  private readonly navigation: Navigation | null;
  readonly loginType: LoginType;
  readonly loginTypes = LoginType;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {
    super();
    this.navigation = this.router.getCurrentNavigation();
    this.loginType = this.navigation?.extras.state?.type as LoginType;
  }

  onSubmit(): void {
    if (this.isLoading()) {
      return;
    }

    const form = this.form.value as ICompleteSignInForm;

    if (this.form.valid) {
      this.signIn(form);
    }
  }

  signIn({ code }: ICompleteSignInForm): void {
    const state = this.navigation?.extras.state as NavigationState;
    const body = { otp: code, [state.type]: state.identifier } as unknown as LoginRequest;

    this.isLoading.set(true);

    this.authService
      .logIn(body, {
        [CAPTCHA_TOKEN_HEADER]: state.captcha,
      })
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading.set(false);
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: async () => {
          const returnUrl = state.returnUrl ?? '/';

          this.store.dispatch(new LoginSuccess(this.loginType));

          await this.router.navigate([returnUrl], {
            queryParamsHandling: 'preserve',
          });
        },
        error: async () => {
          this.store.dispatch(new LoginError(this.loginType));
          await this.router.navigate(['.'], {
            queryParamsHandling: 'preserve',
            onSameUrlNavigation: 'reload',
          });
        },
      });
  }

  resendOtp(): void {
    const state = this.navigation?.extras.state as NavigationState;
    const body = { [state.type]: state.identifier } as unknown as SendOtpRequest;

    this.authService
      .sendOtp(body, {
        [CAPTCHA_TOKEN_HEADER]: state.captcha,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.countdown.reset());
  }
}
