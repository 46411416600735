import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Language } from '@supy.api/dictionaries';

import { APP_CONFIG } from '@supy/core';

export interface CaptchaConfig {
  readonly captcha: {
    readonly siteKey: string;
  };
}

@Component({
  selector: 'supy-captcha',
  templateUrl: './captcha.component.html',
  styleUrl: './captcha.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaptchaComponent),
      multi: true,
    },
  ],
})
export class CaptchaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() protected readonly size: Turnstile.WidgetSize = 'normal';
  @Input() protected readonly theme: Turnstile.Theme = 'light';
  @Input() protected readonly language: Language | 'auto' = 'auto';

  @Output() protected readonly render = new EventEmitter<string>();
  @Output() protected readonly expire = new EventEmitter<string>();
  @Output() protected readonly approve = new EventEmitter<string>();
  @Output() protected readonly reject = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() protected readonly timeout = new EventEmitter<void>();

  #onTouched: () => void;
  #onChanged: (value: string) => void;

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(APP_CONFIG)
    protected readonly config: CaptchaConfig,
  ) {}

  get #container(): HTMLElement {
    const nativeElement = this.elementRef.nativeElement as HTMLElement;

    return nativeElement.querySelector('[container]');
  }

  writeValue(_value: unknown): void {
    // Do nothing
  }

  registerOnChange(fn: (value: string) => void): void {
    this.#onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }

  ngOnInit() {
    this.#render();
  }

  reset() {
    this.#onChanged('');
    turnstile.reset(this.#container);
  }

  #render() {
    const widgetId = turnstile.render(this.#container, {
      sitekey: this.config.captcha.siteKey,
      size: this.size,
      theme: this.theme,
      language: this.language,
      callback: (token: string) => {
        this.#onTouched();
        this.#onChanged(token);
        this.approve.emit(token);
      },
      'error-callback': (error: string) => {
        this.#onTouched();
        this.#onChanged('');
        this.reject.emit(error);
      },
      'expired-callback': token => {
        this.#onTouched();
        this.#onChanged('');
        this.expire.emit(token);
      },
      'timeout-callback': () => {
        this.#onTouched();
        this.#onChanged('');
        this.timeout.emit();
      },
    });

    this.render.emit(widgetId);
  }

  ngOnDestroy(): void {
    turnstile.remove(this.#container);
  }
}
