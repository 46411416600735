import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CaptchaComponent } from './components';

@NgModule({
  imports: [CommonModule],
  declarations: [CaptchaComponent],
  exports: [CaptchaComponent],
})
export class CaptchaModule {}
