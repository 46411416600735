import { isTermInText } from '@supy/common';

import { DropdownTreeNode } from '../models';

interface DropdownTreeNodeExpandArgs<T> {
  readonly node: DropdownTreeNode<T>;
  readonly expand: boolean;
  readonly toggleChildren?: boolean;
  readonly onlyVisible?: boolean;
}

export function toggleNodeExpandAndChildren<T>({
  node,
  expand,
  toggleChildren,
  onlyVisible,
}: DropdownTreeNodeExpandArgs<T>): void {
  node.expanded = expand && (!onlyVisible || !node.hidden);

  if (toggleChildren) {
    node.children?.forEach(child => toggleNodeExpandAndChildren({ node: child, expand, toggleChildren, onlyVisible }));
  }
}

export function resetHiddenAndExpandedNodesRecursively<T>(node: DropdownTreeNode<T>): void {
  node.hidden = false;
  node.expanded = false;

  if (node.children?.length) {
    node.children.forEach(child => {
      resetHiddenAndExpandedNodesRecursively(child);
    });
  }
}

export function setChildrenVisibility<T>(node: DropdownTreeNode<T>, hidden: boolean): void {
  if (node.children?.length) {
    node.children.forEach(child => {
      child.hidden = hidden;
      setChildrenVisibility(child, hidden);
    });
  }
}

export function setNodeHiddenStatus<T>(node: DropdownTreeNode<T>, term: string): boolean {
  const isMatch = isTermInText(node.name, term);
  let hasVisibleChild = false;

  if (node.children?.length) {
    node.children.forEach(child => {
      const childVisible = setNodeHiddenStatus(child, term);

      if (childVisible) {
        hasVisibleChild = true;
      }
    });
  }

  if (isMatch) {
    setChildrenVisibility(node, false);
  } else {
    node.hidden = !isMatch && !hasVisibleChild;
  }

  return !node.hidden;
}

export function collapseNodesRecursively<T>(node: DropdownTreeNode<T>): void {
  node.expanded = !node.selected;

  node?.children?.forEach(child => {
    if (child?.children?.length) {
      collapseNodesRecursively(child);
    }
  });
}
