import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IgxInputDirective, IgxInputGroupComponent, IgxInputGroupType } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { IconColor, IconType } from '../../../icon';
import { AddedContentTemplateDirective, PrefixTemplateDirective, SuffixTemplateDirective } from './input.directives';

export type InputDensity = 'small' | 'medium';

@Component({
  selector: 'supy-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent<T> extends Destroyable implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild(IgxInputGroupComponent, { static: true })
  readonly inputGroup: IgxInputGroupComponent;

  readonly type = input<string>();
  @Input() readonly inputType?: IgxInputGroupType = 'border';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly id?: string;
  @Input() readonly min?: number;
  @Input() readonly max?: number;
  @Input() readonly step?: number;
  @Input() readonly textSelection?: boolean;
  @Input() readonly numeric?: boolean;
  @Input() readonly precision?: number;
  @Input() readonly isLoading: boolean;
  @Input() readonly required: boolean;
  @Input() readonly readonly?: boolean;
  @Input() readonly density: InputDensity = 'small';

  @Input() set prefix(value: IconType) {
    this.shownPrefix = value;
    this.cdr.markForCheck();
  }

  @Input() set suffix(value: IconType) {
    this.shownSuffix = value;
    this.cdr.markForCheck();
  }

  @Input() readonly suffixColor?: IconColor;
  @Input() readonly autocomplete: string = 'off';
  @Input() readonly placeholder?: string;
  @Input() readonly clearable?: boolean;
  @Input() readonly focusOnInit?: boolean;
  @Input() value: T;
  @Input() readonly latency: number = 0;
  @Input() readonly centerText: boolean;
  @Input() readonly noShadow: boolean;
  @Input() readonly lowerCase?: boolean;

  @Output() readonly focusOut = new EventEmitter<FocusEvent>();
  @Output() readonly focusIn = new EventEmitter<FocusEvent>();
  @Output() readonly valueChange = new EventEmitter<T>();
  @Output() readonly cleared = new EventEmitter<T>();
  @Output() readonly inputKeypress = new EventEmitter<KeyboardEvent>();
  @Output() readonly inputKeydown = new EventEmitter<KeyboardEvent>();
  private readonly valueChangeDebouncer: Subject<T> = new Subject<T>();

  @Input() disabled?: boolean;

  @Input() addonText: string;

  @ViewChild(IgxInputDirective) readonly domInput: IgxInputDirective;

  @ContentChild(PrefixTemplateDirective)
  readonly prefixTemplate: PrefixTemplateDirective;

  @ContentChild(SuffixTemplateDirective)
  readonly suffixTemplate: SuffixTemplateDirective;

  @ContentChild(AddedContentTemplateDirective)
  readonly addedContentTemplate: AddedContentTemplateDirective;

  shownPrefix: IconType;
  shownSuffix: IconType;

  fixedType = computed(() => (this.type() === 'number' ? 'text' : this.type()));

  onChange: (value: T) => void;

  onTouched: () => void;

  touched = false;

  get element(): ElementRef<HTMLElement> {
    return this.inputGroup.element;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.valueChangeDebouncer.pipe(takeUntil(this.destroyed$), debounceTime(this.latency)).subscribe(value => {
      this.valueChange.emit(this.lowerCase ? ((value as string).toLowerCase() as T) : value);
    });
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      setTimeout(() => this.focus());
    }
  }

  focus() {
    this.domInput.focus();
  }

  clearInput(): void {
    if (this.clearable) {
      this.value = null;
      this.onChange?.(this.value);
      this.cleared.emit();
    }
  }

  onValueChange(event: Event): void {
    if (!this.disabled) {
      const element = event.target as HTMLInputElement;
      const value =
        this.type() === 'number' && element.value
          ? this.getFixedNumberValue(element.value)
          : this.lowerCase
            ? element.value.toLowerCase()
            : element.value;

      this.value = value as unknown as T;

      this.onChange?.(this.value);

      this.valueChangeDebouncer.next(this.value);
    }
  }

  onInputKeypress($event: KeyboardEvent): void {
    this.inputKeypress.emit($event);
  }

  onFocusOut(e: FocusEvent): void {
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  onFocusIn(e: FocusEvent): void {
    this.focusIn.emit(e);
    this.markAsTouched();
  }

  writeValue(value: T): void {
    this.value = this.lowerCase ? ((value as string).toString().toLowerCase() as T) : value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;

    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }

  handleKeydown(event: KeyboardEvent): void {
    const key = event.key?.toLowerCase();

    this.inputKeydown.emit(event);

    if ([' ', 'spacebar', 'space'].includes(key)) {
      event.stopPropagation();

      if (this.type() === 'tel') {
        event.preventDefault();
      }
    }

    if (this.type() === 'number') {
      const allowedKeys = ['backspace', 'delete', 'arrowleft', 'arrowright', 'tab', 'enter', 'home', 'end', '.'];

      if (
        (!/^\d$/.test(key) && !allowedKeys.includes(key)) ||
        (key === '.' && (event.target as HTMLInputElement).value.includes('.'))
      ) {
        event.preventDefault();
      }
    }
  }

  private getFixedNumberValue(value: string): number | string {
    if (value === '-') {
      return value;
    }

    if (value === '.') {
      return '0.';
    }

    if (value === '-.') {
      return '-0.';
    }

    if (value === '-0' || value === '-0.') {
      return value;
    }

    return value != null ? parseFloat(value.toString()) : 0;
  }
}
