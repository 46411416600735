import '@angular/localize/init';

import { AsiaTimezone, IANATimezone } from '@supy.api/dictionaries';

import { CreditPeriodTypeEnum, SupplierCreditPeriod } from '../entities';
import { InventoryEventType } from '../enums';

const MILLISECONDS_IN_MINUTE = 60 * 1000;

export const DEFAULT_IANA_TIMEZONE = AsiaTimezone.Dubai;

export const DEFAULT_INVENTORY_BACKDATING_LIMIT_IN_MONTHS = 2;
export const DEFAULT_GRN_BACKDATING_LIMIT_IN_MONTHS = 3;

export function getBackdatingBannerMessage({
  kind,
  preventBackdatedUpdates = false,
}: {
  readonly kind: InventoryEventType;
  readonly preventBackdatedUpdates?: boolean;
}): string {
  const monthsLimit = [InventoryEventType.Grn, InventoryEventType.SupplierReturn].includes(kind)
    ? DEFAULT_GRN_BACKDATING_LIMIT_IN_MONTHS
    : DEFAULT_INVENTORY_BACKDATING_LIMIT_IN_MONTHS;

  return preventBackdatedUpdates
    ? $localize`:@@inventory.expirationStockCountBannerMessage.latest:Actions have been disabled on events before the latest stock count date for this location, or older than ${monthsLimit} months. Please contact Supy Support for further assistance`
    : $localize`:@@inventory.expirationStockCountBannerMessage.initial:Actions have been disabled on events before the latest initial stock count date for this location, or older than ${monthsLimit} months. Please contact Supy Support for further assistance`;
}

/**
 * Used to get default date-range-filters, usually month-to-date
 *
 * @param {boolean} overrideWithUTC - Flag to get UTC or Local start-of-day
 * @returns {Date} The start-of-day of the 1st day of the current month
 */
export function getDefaultStartDate(overrideWithUTC?: boolean): Date {
  const date = new Date();

  if (overrideWithUTC) {
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
  } else {
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
  }

  return date;
}

/**
 * Used to get default date-range-filters, usually month-to-date
 *
 * @param {boolean} overrideWithUTC - Flag to get UTC or Local end-of-day
 * @returns {Date} The end-of-day of the current day
 */
export function getDefaultEndDate(overrideWithUTC?: boolean): Date {
  const date = new Date();

  if (overrideWithUTC) {
    date.setUTCHours(23, 59, 59, 999);
  } else {
    date.setHours(23, 59, 59, 999);
  }

  return date;
}

/**
 * Used to shift a given date by the difference between local and given timezone offset
 *
 * @description
 * Used to reverse getDateInTimeZone effect and get back to the system local datetime
 *
 * @param {Date} date - The date to shift
 * @param {number} utcOffset - The offset from UTC; -3 for UTC-3
 * @returns {Date} The date after shifting
 */
export function getShiftedDate(date: Date | number, utcOffset: number): Date {
  if (!date || !Number.isFinite(utcOffset)) {
    throw new Error('Invalid parameters');
  }

  const res = new Date(date);
  const timezoneOffset = getRelativeTimeZoneOffset(utcOffset);

  res.setTime(new Date(date).getTime() + timezoneOffset * MILLISECONDS_IN_MINUTE);

  return res;
}

/**
 * Get the timezone offset in minutes between system and target timezones
 *
 * @param {number} utcOffset - The offset from UTC; -3 for UTC-3
 * @returns {number} The relative timezone offset in minutes
 * @example If system is UTC+3 and target is UTC+4, utcOffset will be 4
 * // returns -60
 * getRelativeTimeZoneOffset(4);
 */
export function getRelativeTimeZoneOffset(utcOffset: number): number {
  if (!utcOffset) {
    return 0;
  }

  const systemTimezoneOffset = new Date().getTimezoneOffset();
  const targetTimezoneOffset = -utcOffset * 60;

  return targetTimezoneOffset - systemTimezoneOffset;
}

/**
 * Get the helper message to indicate working in the retailer timezone
 *
 * @returns {string} The helper message
 */
export function getRetailerTimeZoneHelperMessage(utcOffset: number): string {
  return $localize`:@@common.date.retailerTimezoneHelper:The selected date is as per the retailer's timezone (UTC${
    utcOffset >= 0 ? '+' + utcOffset.toString() : utcOffset
  })`;
}

/**
 * Get the date in the given timezone
 *
 * Note: date objects in js can't really change its timezone so if you log
 * the result of this method it will still gives your timezone but the time
 * will be shifted to match the given timezone
 *
 * @param {Date} date - The passed date
 * @param {IANATimezone} ianaTimeZone - The timezone ID
 * @returns {Date} The new date in the giving timezone
 */
export function getDateInTimeZone(date: Date | string, ianaTimeZone: IANATimezone): Date {
  if (!ianaTimeZone) {
    return new Date(date);
  }

  const tzDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: ianaTimeZone }));

  tzDate.setMilliseconds(new Date(date).getMilliseconds());

  return tzDate;
}

/**
 * Get the date with days difference between the local and given timezones
 *
 * @param {Date} date - The passed date
 * @param {IANATimezone} ianaTimeZone - The timezone ID
 * @returns {Date} The new date with days difference
 */
export function getDateWithDaysDifference(date: Date | number, ianaTimeZone: IANATimezone): Date {
  const currentDate = new Date();
  const timezoneDate = getDateInTimeZone(currentDate, ianaTimeZone);
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + (currentDate.getDate() - timezoneDate.getDate()));

  return newDate;
}

/**
 * Returns the start of the next day in UTC time.
 * @param {Date} date - The date to calculate the next day from.
 * @returns {number} - The start of the next day in UTC time.
 */
export function getStartOfNextDayInUTC(date: Date): number {
  const newDate = new Date(date);

  newDate.setUTCDate(newDate.getUTCDate() + 1);
  newDate.setUTCHours(0, 0, 0, 0);

  return newDate.getTime();
}

/**
 * Returns the UTC timestamp of the start of the day for a given date.
 * @param {Date} date - The input date.
 * @returns {number} - The UTC timestamp of the start of the day.
 */
export function getStartOfDayInUTC(date: Date): number {
  const newDate = new Date(date);

  return Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0);
}

/**
 * Returns the UTC timestamp of the end of the day for a given date.
 * @param {Date} date - The input date.
 * @returns {number} - The UTC timestamp of the end of the day.
 */
export function getEndOfDayInUTC(date: Date): number {
  const newDate = new Date(date);

  return Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999);
}

export function getEndOfDay(date: Date): Date {
  const newDate = new Date(date);

  newDate.setHours(23, 59, 59, 999);

  return newDate;
}

/** Get the date in current time
 *
 * Take the date part of the passed date object and return it with the current time
 *
 * @param {Date} date - The passed date
 * @returns {Date} The new date with current time
 */
export function getDateInCurrentTime(date: Date): Date {
  const dateNow = new Date();

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    dateNow.getHours(),
    dateNow.getMinutes(),
    dateNow.getSeconds(),
    dateNow.getMilliseconds(),
  );
}

/** Get the given date overridden in UTC
 *
 * Take the local time of the passed date object and returns it as if UTC was at that time
 *
 * @param {Date} date - The passed date
 * @returns {Date} The new date with current time
 */
export function getUTCOverriddenDateTime(date: Date, ianaTimeZone: IANATimezone): Date {
  const dateNow = getDateInTimeZone(new Date(date), ianaTimeZone);

  return new Date(
    Date.UTC(
      dateNow.getFullYear(),
      dateNow.getMonth(),
      dateNow.getDate(),
      dateNow.getHours(),
      dateNow.getMinutes(),
      dateNow.getSeconds(),
      dateNow.getMilliseconds(),
    ),
  );
}

/**
 * Compare the date part only of two given date objects
 *
 * @param {Date} date1 - First date object
 * @param {Date} date2 - Second date object
 * @returns {number} The comparison result (-1 | 0 | 1)
 */
export function compareLocalDatePart(date1: Date, date2: Date): -1 | 0 | 1 {
  const newDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0, 0);
  const newDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0, 0);

  return newDate1 > newDate2 ? 1 : newDate1 < newDate2 ? -1 : 0;
}

/**
 * Return the date part as dd-mm-yyyy of a given date
 *
 * @param {Date | string | number} date - The passed date
 * @returns {string} The date part as string
 */
export function getDatePart(date: Date | string | number, split = '/'): string {
  return new Date(date).toISOString().split('T').at(0).split('-').reverse().join(split);
}

/**
 * Returns the re-adjusted real (intended) UTC date to use in requests/payloads. Usually used in tandem with the output value from the date-input component, before constructing the data requests.
 *
 * @param {Date | number} date - The passed date
 * @param {IANATimezone} ianaTimeZone - The timezone ID
 * @param {number} utcOffset - The offset from UTC; -3 for UTC-3
 * @returns {Date} The new date with current time
 */
export function getRetailerTimeZoneShiftedDate(
  date: Date | number,
  ianaTimeZone: IANATimezone,
  utcOffset: number,
): Date {
  return getShiftedDate(getDateWithDaysDifference(date, ianaTimeZone), utcOffset);
}

/**
 * Calculates the resultant date after applying a specific industry-standard credit-period
 *
 * @param {Date} inputDate - User-selected date
 * @param {SupplierCreditPeriod} creditPeriod - Selected credit-period for the channel
 * @returns {Date} The resultant Due Date
 */
export function calculateDueDate(inputDate: Date, creditPeriod?: SupplierCreditPeriod): Date {
  if (isNaN(inputDate.getTime())) {
    return new Date();
  }

  const resultDate = new Date(inputDate);

  if (!creditPeriod?.type) {
    /** Defaults to Net30 if no credit-period mentioned */
    resultDate.setDate(resultDate.getDate() + 30);

    return resultDate;
  }

  switch (creditPeriod.type) {
    case CreditPeriodTypeEnum.Net15: {
      resultDate.setDate(resultDate.getDate() + 15);
      break;
    }

    case CreditPeriodTypeEnum.Net30: {
      resultDate.setDate(resultDate.getDate() + 30);
      break;
    }

    case CreditPeriodTypeEnum.Net60: {
      resultDate.setDate(resultDate.getDate() + 60);
      break;
    }

    case CreditPeriodTypeEnum.MonthEnd: {
      resultDate.setMonth(resultDate.getMonth() + 1, 0);
      break;
    }

    /** Logic changed to always give the end of the next month, since adding 30 days does not always push to next month */
    case CreditPeriodTypeEnum.MonthEndPlus30: {
      resultDate.setMonth(resultDate.getMonth() + 2, 0);

      /** Original Logic: Add 30 days, then shift to end-of-month */
      /**
        resultDate.setDate(resultDate.getDate() + 30);
        resultDate.setMonth(resultDate.getMonth() + 1, 0);
      */

      break;
    }

    /** Logic changed to always give the end of the month after the next, since adding 60 days does not always push to next-next month */
    case CreditPeriodTypeEnum.MonthEndPlus60: {
      resultDate.setMonth(resultDate.getMonth() + 3, 0);

      /** Original Logic: Add 60 days, then shift to end-of-month */
      /**
        resultDate.setDate(resultDate.getDate() + 60);
        resultDate.setMonth(resultDate.getMonth() + 1, 0);
      */
      break;
    }

    case CreditPeriodTypeEnum.InvoiceDate: {
      /** No change needed, invoice date is input date */
      break;
    }

    default:
    case CreditPeriodTypeEnum.Custom: {
      if (!!creditPeriod.value && creditPeriod.value > 0) {
        resultDate.setDate(resultDate.getDate() + creditPeriod.value);
      } else {
        /** Defaults to Net30 if no valid value mentioned */
        resultDate.setDate(resultDate.getDate() + 30);
      }
      break;
    }
  }

  return resultDate;
}

/**
 * Calculates the minimum allowed inventory-event date using stock count and settings information
 *
 * @param options Configuration options to calculate minimum date
 * @param {InventoryEventType} options.eventType - Event type to decide backdating months limit
 * @param {IANATimezone} options.ianaTimeZone - The timezone ID
 * @param {Date} options.lastOpeningStockCountDate - The most-recent opening count date
 * @param {Date} options.lastStockCountDate - The most-recent submitted stock count date
 * @param {boolean} options.preventBackdatedUpdatesSetting - The retailer-setting that decides whether events should be blocked by the latest stock count
 * @returns {Date} The new date that is the minimum allowed date to update/create events
 */
export function getMinimumAllowedInventoryDate({
  eventType,
  ianaTimeZone,
  lastOpeningStockCountDate,
  lastStockCountDate,
  preventBackdatedUpdatesSetting,
}: {
  eventType: InventoryEventType;
  ianaTimeZone: IANATimezone;
  lastOpeningStockCountDate: Date;
  lastStockCountDate: Date;
  preventBackdatedUpdatesSetting: boolean;
}): Date {
  const countBlockingDate = preventBackdatedUpdatesSetting ? lastStockCountDate : lastOpeningStockCountDate;

  const timeBlockingDate = getDateInTimeZone(new Date(), ianaTimeZone);

  const monthsLimit = [InventoryEventType.Grn, InventoryEventType.SupplierReturn].includes(eventType)
    ? DEFAULT_GRN_BACKDATING_LIMIT_IN_MONTHS
    : DEFAULT_INVENTORY_BACKDATING_LIMIT_IN_MONTHS;

  timeBlockingDate.setMonth(timeBlockingDate.getMonth() - monthsLimit);
  timeBlockingDate.setDate(timeBlockingDate.getDate() + 1);

  return countBlockingDate && countBlockingDate?.getTime() > timeBlockingDate.getTime()
    ? countBlockingDate
    : timeBlockingDate;
}
