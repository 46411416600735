import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';

import { ButtonModule } from '../../../button';
import { IconModule, IconType } from '../../../icon';

export type BannerSize = 'small' | 'medium' | 'large';
export type BannerStatus = 'warn' | 'info' | 'error' | 'success';

@Component({
  selector: 'supy-banner',
  standalone: true,
  imports: [CommonModule, IconModule, ButtonModule],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('banner', [
      state('void', style({ height: 0, opacity: 0 })),
      transition(':leave', animate('300ms ease-out')),
    ]),
  ],
})
export class BannerComponent {
  readonly text = input<string>();
  readonly iconName = input<IconType>('info');
  readonly size = input<BannerSize>('medium');
  readonly status = input<BannerStatus>();
  readonly maxWidth = input<string>();
  readonly closable = input<boolean>();

  readonly isOpen = signal(true);

  protected destroyComponent(): void {
    if (!this.closable()) {
      return;
    }

    this.isOpen.set(false);
  }
}
