import { Workbook, Worksheet } from 'exceljs';
import { Observable, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { UploadFile } from '@angular-ex/uploader';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable, MimeType, trackByIndex } from '@supy/common';

import { ButtonModule } from '../../../button';
import { DialogComponent, DialogModule, IDialogComponent } from '../../../dialog';
import { FileUploadModule } from '../../../file-upload';
import { IconModule } from '../../../icon';
import { LoaderModule } from '../../../loader';
import { SelectModule } from '../../../select';

@Component({
  selector: 'supy-import-file-dialog',
  standalone: true,
  imports: [CommonModule, DialogModule, FileUploadModule, ButtonModule, IconModule, SelectModule, LoaderModule],
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportFileDialogComponent extends Destroyable implements IDialogComponent, OnInit {
  @ViewChild(DialogComponent) private readonly dialog: DialogComponent;

  @Input() readonly title: string = 'Import File';
  @Input() readonly maxFileSize: number = 50_000_000;
  @Input() readonly fileTypes: MimeType[] = [MimeType.xls, MimeType.xlsx];

  @Input() readonly isLoading$: Observable<boolean>;

  @Input() readonly isImportDisabled$: Observable<boolean>;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly imported = new EventEmitter<Worksheet>();

  protected readonly workbook = signal<Workbook | null>(null);
  protected readonly selectedSheetName = signal<string | null>(null);
  protected readonly isLoading = signal(false);

  readonly #isImportDisabled = signal(false);

  protected readonly isImportButtonDisabled = computed(() => !this.selectedSheetName());

  protected readonly importButtonLabel = computed(() =>
    this.isLoading()
      ? $localize`:@@common.importingThreeDots:Importing...`
      : $localize`:@@common.actions.import:Import`,
  );

  protected readonly sheetNames = computed<string[]>(() => this.workbook()?.worksheets.map(({ name }) => name) ?? []);

  protected readonly trackByIndex = trackByIndex();

  ngOnInit(): void {
    this.isLoading$.pipe(takeUntil(this.destroyed$)).subscribe(isLoading => this.isLoading.set(isLoading));

    this.isImportDisabled$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isImportDisabled => this.#isImportDisabled.set(isImportDisabled));
  }

  protected onLocalFileUploaded(event: UploadFile[]): void {
    if (!event.length) return;

    const reader: FileReader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        this.workbook.set(await new Workbook().xlsx.load(e.target.result as ArrayBuffer));
      }
    };

    const file = event.at(0)?.nativeFile;

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  }

  protected onChangeFiles(): void {
    this.workbook.set(null);
    this.selectedSheetName.set(null);
  }

  protected onImport(): void {
    if (this.#isImportDisabled() || !this.selectedSheetName() || this.isLoading()) return;

    const worksheet = this.workbook().getWorksheet(this.selectedSheetName());

    this.imported.emit(worksheet);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }
}
