export function isTermInText(text: string, term: string): boolean {
  const lowerText = text.toLowerCase();
  const lowerTerm = term.toLowerCase();

  const textWords = lowerText.split(' ');
  const termWords = lowerTerm.split(' ');

  let termIndex = 0;

  for (const word of textWords) {
    if (termIndex < termWords.length && isSubsequence(word, termWords[termIndex])) {
      termIndex++;
    }

    if (termIndex === termWords.length) {
      return true;
    }
  }

  return false;
}

function isSubsequence(textWord: string, termWord: string): boolean {
  let textIndex = 0;
  let termIndex = 0;

  while (textIndex < textWord.length && termIndex < termWord.length) {
    if (textWord[textIndex] === termWord[termIndex]) {
      termIndex++;
    }

    textIndex++;
  }

  return termIndex === termWord.length;
}
